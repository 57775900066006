























































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import cancelSaveDialog from '@/mixins/cancelSaveDialog'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState, mapGetters } from 'vuex'
import { RawLocation } from 'vue-router'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'Configuration',
  components: {
    TopBar,
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
  },
  data () {
    return {
      title: this.$t('configuration.form.title'),
      errors: [],
      test: {},
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('configurationForm', [
      'configuration.value',
    ]),
    ...mapState('configurationForm', {
      configuration: 'configuration',
      isConfigurationSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    idConfiguration () {
      return this.$route.params.idConfiguration || null
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      await this.reset()
      if (this.idConfiguration) {
        await this.loadConfigurationById(
          this.idConfiguration,
        )
      }
    },
    ...mapActions('configurationForm', {
      save: 'save',
      loadConfigurationById: 'loadById',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.configuration)
        await this.sendSuccessMessage('configuration.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Configuration List' })
      }
    },
  },
})
